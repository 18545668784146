<div class="gdu-container">
  <div class="gdu-logo-wrapper">
    <img class="mb-4" src="../assets/img/logos/Logo-Akilee-00.png" alt="" width="500">
  </div>
  <h1><strong>Gestion des utilisateurs</strong></h1>
  <div class="text-xl font-weight-bold pt-4">Mot de passe oublié ?</div>
  <div class="gdu-form-wrapper">
    <form >
      <div class="gdu-form-row">

        <!-- texte -->
        <div class="gdu-form-group">
          <label>
            Remplissez le formulaire ci-dessous et nous vous enverrons par email les informations nécessaires pour accéder à votre compte.
          </label>
        </div>
        <!-- email -->
        <div class="gdu-form-group py-0">
          <label for="email" class="gdu-form-label">
            Email <span style="color: #DC2626">*</span>
          </label>
          <input id="email"
                 type="text"
                 placeholder="Saisissez votre email : you@exemple.com"
                 ngControl="email"
                 pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$"
                 class=" text-sm form-control gdu-form-group py-0 leading-tight focus:outline-none focus:bg-white"
                 [(ngModel)]="newEmail" name="userMail" #userMail="ngModel" required [class.is-invalid]="userMail.touched && userMail.invalid">
          <div *ngIf="userMail.touched && userMail.invalid" class="invalid-feedback">
            L'adresse email est obligatoire
          </div>
          <div *ngIf="userMail.errors && userMail.errors.pattern" class="invalid-feedback" >
            L'adresse email est invalide
          </div>
        </div>
        <div class="gdu-form-group">
          <label class="text-xs italic text-gray-400">
            Indiquez l'email que vous nous avez communiqué lors de la création de votre compte.
          </label>
        </div>

        <button class="gdu-button" type="submit" (click)="envoyer();">ENVOYER</button>
      </div>
    </form>

  </div>
</div>
<ngx-loading [show]="loading" [config]="ngxLoadingConfig"></ngx-loading>

