import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AkiAuthService } from 'src/app/core/services/aki-auth.service';
import { ngxLoadingConfig } from 'src/app/util/variable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  ngxLoadingConfig: any;
  loginResponse: any;
  loginError: any;
  mailUtilisateur: any;
  constructor(private authService: AkiAuthService,
              private formBuilder: FormBuilder,
              private modalService: NgbModal,

              private notificationService: NotificationsService,
              private route: ActivatedRoute,
              private router: Router
    )
    {  this.loginForm = this.formBuilder.group({
      login: '',
      password: '',
      grantType: 'password'
    });
       this.ngxLoadingConfig = ngxLoadingConfig;

   // this.returnURL = "home"
  }

  ngOnInit(): void {

    this.emptyStorage();
  }

  openModal(content: any){
    this.modalService.open(content, {centered: true, size: 'xl'});
  }

  onSubmit(loginData: any) {
    this.loading = true;
    this.authService.login(loginData.login, loginData.password, loginData.grantType).subscribe(
      (response: any) => {
        // this.loginResponse = response;
        // this.loginError = null;
        this.router.navigate(['/home/utilisateurs']);
      },
      (error: any) => {
        console.log('erreurrrrrr', error);
        this.loginError = error;
        this.loginResponse = null;
        if (error.error.code === 9111 || error.error.code === 9120){
        this.notificationService.error('Erreur', 'Identifiants incorrects', {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight',
        });
        this.loading = false;
        this.loginForm.controls.login.reset();
        this.loginForm.controls.password.reset();
      }
        if (error.error.code === 9310){
        this.notificationService.error('Erreur', 'Votre compte utilisateur a été bloqué', {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight',
        });
        this.loading = false;
        this.loginForm.controls.login.reset();
        this.loginForm.controls.password.reset();
      }
        if (error.error.code === 9313){
        this.notificationService.error('Erreur', 'Votre compte utilisateur a été vérouillé', {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight',
        });
        this.loading = false;
        this.loginForm.controls.login.reset();
        this.loginForm.controls.password.reset();
      }
        if (error.error.code === 9311){
        this.notificationService.error('Erreur', 'La période de validité de votre compte a expiré', {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight',
        });
        this.loading = false;
        this.loginForm.controls.login.reset();
        this.loginForm.controls.password.reset();
      }
    }
    );
      // this.loading = false;

  }

  checkToken() {
    this.authService.checkToken().subscribe(
      response => alert(response),
      error => alert(error)
    );
  }

  emptyStorage() {
    localStorage.clear();
  }

  envoyerLiendeReinitialisation(){
    this.loading = true;
    this.authService.forgetPassword(this.mailUtilisateur).subscribe(
      (response: any) => {
        this.notificationService.success('Succés', 'Vous recevrez un lien de réinitialisation de votre mot de passe à cette adresse mail', {
          timeOut: 1000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight',
        });
        this.loading = false;
        console.log('response', response);

      },
      (error: any) => {
        this.notificationService.error('Erreur', 'adresse mail non envoyée', {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight',
        });
        this.loading = false;

        console.log('error', error);

      }
    );
  }

}
