<div class="gdu-container">
  <div class="gdu-logo-wrapper">
    <img class="mb-4" src="../assets/img/logos/Logo-Akilee-00.png" alt="" width="500">
  </div>
  <h1><strong>Gestion des utilisateurs</strong></h1>
  <div class="text-xl font-weight-bold pt-4">Mot de passe oublié ?</div>
  <div class="gdu-form-wrapper">
    <form >
      <div class="gdu-form-row">
        <!-- texte -->
        <div class="gdu-form-group align-items-center">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" height="64" width="64"
               viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
            <path style="fill:#A5EB78;" d="M433.139,67.108L201.294,298.953c-6.249,6.249-16.381,6.249-22.63,0L78.861,199.15L0,278.011
              l150.547,150.549c10.458,10.458,24.642,16.333,39.431,16.333l0,0c14.788,0,28.973-5.876,39.43-16.333L512,145.968L433.139,67.108z"/>
            <g style="opacity:0.1;">
              <path d="M485.921,119.888L187.59,418.22c-8.254,8.253-18.633,13.882-29.847,16.391c9.363,6.635,20.608,10.28,32.235,10.28l0,0
                c14.788,0,28.973-5.876,39.43-16.333L512,145.966L485.921,119.888z"/>
            </g>
          </svg>
          <label>
            Votre demande de ré-initialisation de mot de passe a été bien prise en compte.<br>
            Vous allez recevoir un email avec les instructions à suivre pour accéder de nouveau à votre compte.
          </label>
        </div>
      </div>
    </form>

  </div>
</div>
