import {Options} from '../interfaces/options';

export const DEFAULT_OPTIONS: Options = {
  serverUrl: "",
  loginEndpoint: "",
  loginFieldName: 'login',
  passwordFieldName: 'password',
  checkTokenEndpoint: "",
  app: null,
  clientId: null,
  clientSecret: null,
  grantType: null
};

