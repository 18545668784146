import { Component, OnInit } from '@angular/core';

import {FormBuilder, FormGroup} from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { UserToken } from 'src/app/core/interfaces/user-token';
import { AkiAuthService } from 'src/app/core/services/aki-auth.service';
import { ngxLoadingConfig } from 'src/app/util/variable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['../../login/login.component.scss','./login-error.component.scss']
})
export class LoginErrorComponent implements OnInit {
  loading = false;
  ngxLoadingConfig:any;
 // returnURL:string;
  loginResponse: any;
  loginError:any;
  newEmail: any;

  constructor(private authService: AkiAuthService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,

    private notificationService: NotificationsService,

    private router: Router) { }

  ngOnInit(): void {
  }

  envoyer(){
    this.loading = true;
    this.authService.forgetPassword(this.newEmail).subscribe(
      (response:any) =>{
        // this.notificationService.success('Succés', 'Vous recevrez un lien de réinitialisation de votre mot de passe à cette adresse mail', {
        //   timeOut: 1000,
        //   showProgressBar: false,
        //   pauseOnHover: true,
        //   clickToClose: true,
        //   animate: 'fromRight',
        // });
        this.router.navigate(["/login-forgot/login-success"]);
        this.loading = false;
        console.log('response', response);

      },
      (error:any)=>{
        if(error.error.code === 9511){

        this.notificationService.error('Erreur', "L'adresse mail saisie ne correspondant pas à celle fournie lors de la création de votre compte ", {
          timeOut: 5000,
          showProgressBar: false,
          pauseOnHover: true,
          clickToClose: true,
          animate: 'fromRight',
        });
        }
        this.router.navigate(['/login-forgot/login-error']);

        this.loading = false;

        console.log('error', error);

      }
    );
  }

}
