import { Inject, Injectable } from '@angular/core';
import { Options } from '../interfaces/options';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserToken } from '../interfaces/user-token';

@Injectable()
export class AkiAuthService {
  httpOptions = {
    headers: new HttpHeaders({}),
    responseType: 'blob',
  };
  constructor(
    @Inject('options') public options: Options,
    private http: HttpClient
  ) {}

  login(
    login: string,
    password: string,
    grantType: string
  ): Observable<UserToken> {
    return this.http
      .post<UserToken>(this.options.serverUrl + this.options.loginEndpoint, {
        username: login,
        password,
        grant_type: grantType,
      })
      .pipe(
        map((response: any) => {
          const userToken: UserToken = {
            accessToken: response.access_token,
            tokenType: response.token_type,
            expiresIn: response.expires_in,
            createdAt: response.created_at,
            user: {
              id: response.user.id,
              fuid: response.user.fuid,
              email: response.user.email,
              username: response.user.username,
              nom: response.user.nom,
              prenom: response.user.prenom,
              telephone: response.user.telephone,
              service: response.user.service,
              isBlocked: response.user.is_blocked,
              isActive: response.user.is_active,
              blockedAt: response.user.blocked_at,
              activeAt: response.user.active_at,
              firstConnexion: response.user.first_connexion,
              permission_assignments: response.user.permission_assignments,
              permissions: response.user.permissions,
              role_assignments : response.user.role_assignments
            },
          };
          localStorage.setItem('userToken', JSON.stringify(userToken));
          return userToken;
        })
      );
  }

  checkToken(): Observable<boolean> {
    return this.http.post<boolean>(
      this.options.serverUrl + this.options.checkTokenEndpoint, this.httpOptions
    );
  }

  getUserToken(): UserToken {
    return JSON.parse(localStorage.getItem('userToken') || '{}') as UserToken;
  }

  logout(): void {
    localStorage.removeItem('userToken');
  }

  getUtilisateur(): Observable<any> {
     return this.http.get(this.options.serverUrl + '/auth/users/');
  }

  getPermissionList(): Observable<any>{
    return this.http.get(
      this.options.serverUrl + '/auth/permissions');
  }
  getActivePermissionList(): Observable<any>{
    return this.http.post(
      this.options.serverUrl + '/auth/permissions/list/active',
      this.httpOptions
    );
  }

  getUserById(id: number): Observable<any>{
    return this.http.get(this.options.serverUrl + '/auth/users/' + id);
  }

  getRoleById(id: number): Observable<any>{
    return this.http.get(this.options.serverUrl + '/auth/roles/' + id);
  }


  addRole(nom: string, code: string, libelle: string, permissions: [{path: string, values: any}]): Observable<any>{
    const body = {
      nom,
      code,
      description: libelle,
      permissions
    };
    return this.http.post(
      this.options.serverUrl + '/auth/roles',
      body
    );
  }

  modifyRole(id: number, nom: string, code: string, libelle: string,
             permissions: [{path: any, values: any, valid_from: string, valid_to: string}],
    ): Observable<any>{
  const  body = {
    nom,
    code,
    description: libelle,
    permissions,
    };
  return this.http.put(
      this.options.serverUrl + '/auth/roles/' + id,
      body
    );
  }

  getRoleList(): Observable<any>{
    return this.http.get(
      this.options.serverUrl + '/auth/roles');
  }
  getActiveRoleList(): Observable<any>{
    return this.http.post(
      this.options.serverUrl + '/auth/roles/list/active',
      this.httpOptions
    );
  }

  imitateProfil(utilisateur: string): Observable<any>{
    const body = {
      username: utilisateur
    };
    return this.http.post(
      this.options.serverUrl + '/auth/users/imitate', body
    ) .pipe(
      map((response: any) => {
        const userToken: UserToken = {
          accessToken: response.access_token,
          tokenType: response.token_type,
          expiresIn: response.expires_in,
          createdAt: response.created_at,
          user: {
            id: response.user?.id,
            fuid: response.user?.fuid,
            email: response.user?.email,
            username: response.user?.username,
            nom: response.user?.nom,
            prenom: response.user?.prenom,
            telephone: response.user?.telephone,
            service: response.user?.service,
            isBlocked: response.user?.is_blocked,
            isActive: response.user?.is_active,
            blockedAt: response.user?.blocked_at,
            activeAt: response.user?.active_at,
            firstConnexion: response.user?.first_connexion,
            permission_assignments: response.user.permission_assignments,
            permissions: response.user.permissions,
            role_assignments : response.user.role_assignments
          },
        };
        localStorage.setItem('userToken', JSON.stringify(userToken));
        return userToken;
      })
      );
  }
  addUser(nom: string, prenom: string, client: number, email: string, username: string,
          roles: [{code: any, valid_from: string, valid_to: string, values: any}], gateways: any, all: string): Observable<any>{
    const body = {
      nom,
      prenom,
      email,
      username,
      client_id: client,
      all,
      roles,
      gateways
    };
    return this.http.post(
      this.options.serverUrl + '/auth/users',
      body
    );
  }
  updateUser(id: number, nom: string, prenom: string, client: number, email: string,
  valid_from: string, valid_to: string, block: boolean, unlock: boolean, roles: [{code: any, valid_from: string, valid_to: string, values: any}],
  gateways: any, all: string): Observable<any>{
    const body = {
      nom,
      prenom,
      client_id: client,
      all,
      gateways,
      email,
      valid_from,
      valid_to,
      block,
      unlock,
      roles
    };
    console.log('Body', body);
    return this.http.put(
      this.options.serverUrl + '/auth/users/' + id,
      body
    );
  }
  activateRole(id: number): Observable<any>{
    return this.http.put(
      this.options.serverUrl + '/auth/roles/' + id + '/activate',
      this.httpOptions
    );
  }
  deactivateRole(id: number): Observable<any>{
    return this.http.put(
      this.options.serverUrl + '/auth/roles/' + id + '/deactivate',
      this.httpOptions
    );
  }
  activatePermission(id: number): Observable<any>{
    return this.http.put(
      this.options.serverUrl + '/auth/permissions/' + id + '/activate',
      this.httpOptions
    );
  }
  deactivatePermission(id: number): Observable<any>{
    return this.http.put(
      this.options.serverUrl + '/auth/permissions/' + id + '/deactivate',
      this.httpOptions
    );
  }

  authorize(permissions: [{permission: string, values: any}]): Observable<any>{
    const body = {
      permissions
    };
    return this.http.post(
      this.options.serverUrl + '/auth/control/display',
      body
    );


  }
  deleteUser(id: number): Observable<any>{
    return this.http.delete(
      this.options.serverUrl + '/auth/users/' + id
    );
  }
  unDeleteUser(id: number): Observable<any>{
    return this.http.put(
      this.options.serverUrl + '/auth/users/' + id + '/restore',
      this.httpOptions
    );
  }

  forgetPassword(email: any): Observable<any>{
    const body = {
      email
    };
    return this.http.post<any>(
      this.options.serverUrl + '/auth/users/password-forgotten',
      body
    );
  }

  getDeletedUsersList(): Observable<any>{
    return this.http.post(
      this.options.serverUrl + '/auth/users/list/deleted',
      this.httpOptions
    );
  }

  getSitesOfClient(clientId: number): Observable<any> {
    return this.http.get(this.options.serverUrl + '/clients/' + clientId + '/gateways');
  }

   getListOfClient(): Observable<any> {
    return this.http.get(this.options.serverUrl + '/clients');
  }


}
