import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDateAdapter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule } from 'ngx-loading';
import { AkiAuthService } from 'src/app/core/services/aki-auth.service';
import { AkiAuthModule } from 'src/app/core/aki-auth.module';
import { NotificationsService, SimpleNotificationsModule } from 'angular2-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginResetComponent } from './login-reset/login-reset.component';
import { LoginForgotComponent } from './login-forgot/login-forgot.component';
import { LoginErrorComponent } from './login-forgot/login-error/login-error.component';
import { LoginSuccessComponent } from './login-forgot/login-success/login-success.component';
import { ErrorInterceptor } from 'src/app/core/interceptors/error.interceptor';
import { NgbDateStructAdapter } from 'src/app/core/services/ngbdateStructAdapter.service';
import {environment} from '../environments/environment';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginResetComponent,
    LoginForgotComponent,
    LoginErrorComponent,
    LoginSuccessComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    SimpleNotificationsModule.forRoot({
      position: ['top', 'center'],
      maxStack: 10,
      icons: {
        success: '<i class="icon-check-sign icon-3x"></i>',
        alert: '<img src="../assets/images/close.png"/>',
        error: '<i class="icon-bug icon-3x"></i>',
        info: '<i class="icon-info icon-3x"></i>',
        warn: '<i class="icon-warning-sign icon-3x"></i>'
      }
    }),
    BrowserAnimationsModule,
    FormsModule,
    AkiAuthModule.forRoot({
      serverUrl: environment.server,
      loginEndpoint: '/auth/token/access',
      checkTokenEndpoint: '/auth/token/info',
    }),
    ReactiveFormsModule,
    NgbModule,
    NgxLoadingModule.forRoot({}),

  ],
  providers: [
    AkiAuthService,
    NotificationsService,
    { provide: NgbDateAdapter, useClass: NgbDateStructAdapter },

   { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
