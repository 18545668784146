import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { AkiAuthGuard } from 'src/app/core/guards/aki-auth.guard';
import {LoginResetComponent} from "./login-reset/login-reset.component";
import {LoginForgotComponent} from "./login-forgot/login-forgot.component";
import {LoginErrorComponent} from "./login-forgot/login-error/login-error.component";
import {RolesListComponent} from "./home/pages/roles/roles-list/roles-list.component";
import {LoginSuccessComponent} from "./login-forgot/login-success/login-success.component";

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
    // canActivate:[AkiAuthGuard]
  },
  {
    path:'home',
    loadChildren: ()=> import('./home/home.module').then(m=>m.HomeModule),
    canActivate:[AkiAuthGuard],
    data: {redirectTo: 'login'}
  },
  {
    path:'login' ,
    component:LoginComponent
  },
  {
    path:'login-reset' ,
    component:LoginResetComponent
  },
  {
    path: 'login-forgot',
    children: [
      {
        path: '',
        redirectTo: 'login-forgot',
        pathMatch: 'full',
      },
      {
        path: 'login-forgot',
        component: LoginForgotComponent,
      },
      {
        path: 'login-error',
        component: LoginErrorComponent
      },
      {
        path: 'login-success',
        component: LoginSuccessComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: ''
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers:[AkiAuthGuard]
})
export class AppRoutingModule {}
