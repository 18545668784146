import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'gdu-web';
}

export class OrderCondition{
  name:string;
  direction:number;
  clicked:number;
  constructor(name:any){
    this.name = name;
    this.clicked = 1;
    this.direction = 1; //asc
  }
  click(){
    this.clicked++;
    this.chechDirection();
  }
  chechDirection(){
    const mod = this.clicked % 3;
    switch(mod){
      case 0:
        this.direction = 0;
      break;
      case 1:
        this.direction = 1;
      break;
      case 2:
        this.direction = -1;
      break
    }
  }
}