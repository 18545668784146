<div class="gdu-container">
  <div class="gdu-logo-wrapper">
    <img class="mb-4" src="../assets/img/logos/Logo-Akilee-00.png" alt="" width="500">
  </div>
  <h1><strong>Gestion des utilisateurs</strong></h1>
  <div class="gdu-form-wrapper">
<form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)">
  <div class="gdu-form-row">
  <div class="gdu-form-group">
    <label for="login" class="gdu-form-label">
      Nom d'utilisateur
    </label>
    <input id="login" type="text" formControlName="login" placeholder="Saisissez votre identifiant">
  </div>

  <div class="gdu-form-group">
    <label for="password" class="gdu-form-label">
      Mot de passe
    </label>
    <input id="password" type="password" formControlName="password" placeholder="Saisissez votre mot de passe">
  </div>
  <button class="gdu-button" type="submit">Se connecter</button>
</div>
</form>
<a class="forgotten text-blue-600 text-sm underline" routerLink="/login-forgot" style="cursor: pointer;">Mot de passe oublié ?</a>

</div>
</div>
<ngx-loading [show]="loading" [config]="ngxLoadingConfig"></ngx-loading>
<ng-template #ForgotPasswordContent let-modal>
      <div class="modal-container fixed inset-0 min-h-screen min-w-full flex items-center justify-center h-auto">
        <div class="modal-content bg-white rounded-2xlg md:max-w-lg md:mx-auto fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative" >
          <div class="rounded-lg bg-red-600 text-xl py-3 font-medium text-white tracking-wider w-full flex flex-row">
            <div class="modal-title text-center flex-auto font-bold" id="modal-basic-title"> Mot de passe oublié</div>
            <div>
              <button type="button" class="close object-right-top mr-2 flex-auto outline-none flex-auto text-white" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
  <div class="modal-body">
    <div class="form-group">
          <label class="control-label"><b>Votre adresse mail*</b></label>
          <input type="text" name="adressemailutilisateur" [(ngModel)]="mailUtilisateur" class="form-control" placeholder="Veuillez entrer votre adresse mail">
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
    class="rounded-focus-hack bg-red-600 hover:bg-yellow-500 text-white text-md-center font-medium hover:text-white p-1 rounded-lg hover:shadow-lg transition-all duration-300 border-red-600 hover:border-transparent"
    (click)="envoyerLiendeReinitialisation();modal.close('Save'); ">ENVOYER LE LIEN DE REINITIALISATION</button>
  </div>
</div>
</div>
</ng-template>

<!-- <button *ngIf="loginResponse" class="button" (click)="checkToken()">Verifier le token</button>
<button class="button" (click)="emptyStorage()">Vider le local storage</button>
<button routerLink="/auth/guard-check">Tester le guard</button>

<div *ngIf="loginResponse || loginError">
  <pre *ngIf="loginResponse" style="color: #125699">
    <h3>Vous êtes connecté!</h3>
    <p>{{loginResponse | json}}</p>
  </pre>
  <pre *ngIf="loginError" style="color: red">
    <h3>Erreur lors de la connexion</h3>
    <p>{{loginError | json}}</p>
  </pre>
</div> -->
