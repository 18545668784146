<div class="gdu-container">
  <div class="gdu-logo-wrapper">
    <img class="mb-4" src="../assets/img/logos/Logo-Akilee-00.png" alt="" width="500">
  </div>
  <h1><strong>Gestion des utilisateurs</strong></h1>
  <div class="gdu-form-wrapper">
    <form >
      <div class="gdu-form-row">

        <!-- Mot de passe -->
        <div class="gdu-form-group">
          <label for="password1" class="gdu-form-label">
            Mot de passe
          </label>
          <input id="password1" type="password" formControlName="password" placeholder="Saisissez votre mot de passe">
        </div>
        <!-- Confirmation du Mot de passe -->
        <div class="gdu-form-group">
          <label for="password2" class="gdu-form-label">
            Confirmation Mot de passe
          </label>
          <input id="password2" type="password" formControlName="password" placeholder="Saisissez votre mot de passe">
        </div>
        <!-- Code de ré-initialisation -->
        <div class="gdu-form-group">
          <label for="code" class="gdu-form-label">
            Code de ré-initialisation
          </label>
          <input id="code" type="text" formControlName="login" placeholder="Saisissez votre code de ré-initialisation">
        </div>

        <button class="gdu-button" type="submit">VALIDER</button>
      </div>
    </form>

  </div>
</div>
