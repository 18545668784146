import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AkiAuthService} from '../services/aki-auth.service';
import {map} from 'rxjs/operators';

@Injectable()
export class AkiAuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AkiAuthService) {
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userToken = this.authService.getUserToken();
    const routeDataRedirectTo = !!next && next.data ? (next.data.redirectTo) : null;
    if (userToken && Object.keys(userToken).length !== 0) {
      return this.authService.checkToken().pipe(
        map(logged => {
          if (!logged) {
            this.redirectIfSet(routeDataRedirectTo);
            return false;
          }
          return true;
        })
      );
    } else {
    this.redirectIfSet(routeDataRedirectTo);
    return false;
    }
  }

  redirectIfSet(redirectTo: any): void {
    if (redirectTo) {
      this.router.navigate([redirectTo]);
    }
  }
}

