import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {AkiAuthService} from './services/aki-auth.service';
import {Options} from './interfaces/options';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AkiAuthGuard} from './guards/aki-auth.guard';
import {DEFAULT_OPTIONS} from './consts/default-options.const';
import {AkiAuthInterceptor} from './interceptors/aki-auth.interceptor';

export const OPTIONS = new InjectionToken<Options>('options');
export function optionsFactory(options:Options) {
  return {
    ...DEFAULT_OPTIONS,
    ...options
  };
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AkiAuthInterceptor, multi: true }
  ],
  exports: []
})
export class AkiAuthModule {
  static forRoot(options: Options): ModuleWithProviders<AkiAuthModule> {
    return {
      ngModule: AkiAuthModule,
      providers: [
        AkiAuthService,
        AkiAuthGuard,
        { provide: OPTIONS, useValue: options },
        { provide: 'options', useFactory: optionsFactory, deps: [OPTIONS] }
        ]
    };
  }
}
