import { Component, OnInit } from '@angular/core';

import {FormBuilder} from '@angular/forms';

import { Router } from '@angular/router';
import { NotificationsService } from 'angular2-notifications';
import { AkiAuthService } from 'src/app/core/services/aki-auth.service';
import { ngxLoadingConfig } from 'src/app/util/variable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-login-forgot',
  templateUrl: './login-forgot.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class LoginForgotComponent implements OnInit {
  loading = false;
  ngxLoadingConfig: any;
  loginResponse: any;
  loginError: any;
  newEmail: any;
  constructor(private authService: AkiAuthService,
              private formBuilder: FormBuilder,
              private modalService: NgbModal,

              private notificationService: NotificationsService,

              private router: Router
    )
   {
    this.ngxLoadingConfig = ngxLoadingConfig;

  }

  ngOnInit(): void {


  }

  envoyer(){
    this.loading = true;
    this.authService.forgetPassword(this.newEmail).subscribe(
      (response: any) => {
        this.router.navigate(['/login-forgot/login-success']);
        this.loading = false;
        console.log('response', response);

      },
      (error: any) => {
        if (error.error.code === 9511){

          this.notificationService.error('Erreur', 'L\'adresse mail saisie ne correspondant pas à celle fournie lors de la création de votre compte ', {
            timeOut: 5000,
            showProgressBar: false,
            pauseOnHover: true,
            clickToClose: true,
            animate: 'fromRight',
          });
          }
        this.router.navigate(['/login-forgot/login-error']);

        this.loading = false;

        console.log('error', error);

      }
    );
  }

}
